@import '../../../sass/index.scss';

.card-brand-item {
    // background-color: aqua;
    cursor: pointer;
    transition: transform 0.3s ease;

    .card-brand-item-image {
        height: 85px;
        @include mobile{
            height: 100px;
        }
    }

    .card-brand-item-title {
        font-family: $roboto;
        font-size: 18px;
        font-weight: 700;
        color: $txt-white;
    }
}

.card-brand-item:hover {
    transform: scale(1.1);
    background-color: #5C4033;
}