@import '../../../sass/index.scss';

.homeslider-item-container {
    @include flex(center, center);
    background-color: $main-bg;

    .homeslider-item-container-content {
        background-color: $main-bg;
        @include flex(center, center);
        width: 100%;

        .homeslider-item-container-content-main {
            display: flex;
            flex-direction: column;
            padding-top: 20px;
            // padding: 20px 50px 34px 50px;
            width: 100%; //penting
            max-width: 1500px;

            @include mobile {
                // padding: 0px;
                height: 100%;
                
            }
        }
    }
}