@import '../../../sass/index.scss';

.navbar-item-container {
    background-color: #5C4033;
    position: fixed; // Sticky Navbar
    width: 100%;
    height: 76px;
    border-bottom: 5px solid #6F4E37;
    z-index: 10;
    @include mobile{
        width: 100%;
    }

    .navbar-item-content {
        height: 100%;
        @include flex(center, space-between);
        padding: 0 30px;

        .navbar-content-logo-container {
            width: 100%;

            @include mobile {
                @include flex(center, center);
            }

            .navbar-content-logo {
                height: 45px;
                display: flex;
    
                @include mobile {
                    display: none;
                }
            }
    
            .navbar-content-logo-mobile {
                height: 52px;
                display: none;
    
                @include mobile {
                    display: flex;
                }
            }
        }

        .navbar-content-menu {
            @include flex(center, flex-end);
            // height: 100%;
            width: 100%;

            @include tablet {
                display: none;
            }
        
            @include mobile {
                display: none;
            }
        }

        .navbar-content-bars {
            display: none;
            align-items: center;
            cursor: pointer;

            position: absolute;
            top: 25px;
            right: 30px;

            .navbar-content-bars-icon {
                color: #ffffff;
            }

            @include tablet {
                display: flex;
            }
        
            @include mobile {
                display: flex;
            }
        }

        .navbar-content-menu-mobile {
            @include flex(flex-start, center);
            flex-direction: column;
            position: absolute;
            top: 76px + 5px;
            left: 0;
            background-color: #6F4E37;
            height: 384px;
            width: 100%;

            @include mobile {
                height: 264px;
            }
        }

        .navbar-content-menu-mobile.active {
            opacity: 1;
            visibility: visible;
            top: 76px + 5px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
          
        .navbar-content-menu-mobile.inactive {
            opacity: 0;
            visibility: hidden;
            top: 96px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
}