@import '../../sass/index.scss';

.approuter-item-container {
    display: flex;
    flex-direction: column;
    // height: 100vh;

    .approuter-item-content {
        flex: 1;
        padding-top: 76px + 5px;
    }
}