@import '../../../sass/index.scss';

.homebrand-item-container {
    height: 100%;
    @include flex(center, center);
    background-color: #5C4033;

    @include mobile {
        height: 100%;
        // object-fit: cover;
    }

    .homebrand-item-content {
        background-color: #5C4033;
        @include flex(center, center);
        // width: 100%;
        height: 100%;

        .homebrand-item-content-main {
            display: flex;
            flex-direction: column;
            padding: 40px 30px;
            // width: 100%; //penting
            // max-width: 1280px;

            .homebrand-item-content-list {
                @include flex(center, center);
                flex-wrap: wrap;
                column-gap: 270px;
                row-gap: 40px;
                width: 100%;
                // padding: 0px 100px 0px 100px;

                @include mobile{
                    width: 0;
                    column-gap: 100px;
                }
                
            }
        }
    }
}