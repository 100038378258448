@import '../../sass/index.scss';

.productdetail-page-container {
    @include flex(center, center);
    background-color: $main-bg;
    color: white;
    
    .productdetail-page-content {
        background-color: $main-bg;
        width: 100%;
        @include flex(center, center);
        @include mobile{
            width: 100%;
            
        }

        .productdetail-page-content-main {
            display: flex;
            flex-direction: column;
            // padding: 0 10px;
            width: 100%; //penting
            max-width: 1500px;

            

            .productdetail-page-content-main-image {
                margin-bottom: 24px;
            }

            .productdetail-page-content-main-title {
                @include mobile{
                    padding-left: 20px;
                }
                font-family: $roboto;
                font-size: 34px;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: 0.0025em;
                color: white;    
                margin-bottom: 24px;
                padding-left: 20px;
            }

            .productdetail-page-content-main-subtitle-container {
                display: flex;
                column-gap: 50px;
                margin-bottom: 34px;
                padding-right: 20px;
                padding-left: 20px;

                @include mobile {
                    flex-direction: column;
                }

                .productdetail-page-content-main-subtitle {
                    flex: 1;
                    width: 95%;
                    font-family: $roboto;
                    font-size: 16px;
                    font-weight: 400;
                    color: white;
                    text-align: justify;
                    margin-bottom: 16px;                    
                    // padding-left: 10px;
                    // padding-right: 10;
                    @include mobile {
                        // padding-left: 10px;
                    }
                }

                .productdetail-page-content-main-table {
                    flex: 1;
                }
            }
        }
    }
}