@import '../../../sass/index.scss';

.motion-dropdown-menu {
  position: absolute;
  top: 40px;
  background-color: #6F4E37;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 12px 60px rgba(14, 32, 66, 0.15),
    0 1px 2px rgba(14, 32, 66, 0.05);
  padding: 5px 15px;
  width: 200px;
  z-index: 1000;
  transition: transform 0.5s ease;

  .motion-dropdown-item {
    display: flex;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 5px;
    text-decoration: none;

    .motion-dropdown-item-title {
      text-align: left;
      font-family: $roboto;
      font-size: 14px;
      font-weight: 400;
      color: $dropdown-txt;
    }
  }

  .motion-dropdown-item:hover {
    background-color: rgb(170, 82, 0);
    transform: scale(1.3);
    @include mobile{
      background-color: $red
    }

    .motion-dropdown-item-title {
      color: $txt-white;
      font-weight: 500;
    }
  }
}

.motion-dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  top: 40px;
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.motion-dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
  // top: 30px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
