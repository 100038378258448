@import '../../../sass/index.scss';

.card-slider-item {
    cursor: pointer;

    .card-slider-item-image {
        width: 100%;
        // height: 500px;
        // object-fit: cover;

        @include mobile {
            // height: 150px;
            // width: 100%;
            // padding-top: 10px;
            // object-fit: cover;
        }
    }
}