@import '../../../sass/index.scss';

.homecontact-item-container {
    @include flex(center, center);
    // flex-direction: column;

    .homecontact-item-container-content {
        background-color: $main-bg;
        @include flex(center, center);
        width: 100%;
        margin: 30px 30px;
        @include mobile{
            margin: 0px;
            margin-bottom: 30px;
        }

        .homecontact-item-container-content-main {
            display: flex;
            // flex-direction: column;
            // padding: 34px 50px;
            width: 100%; //penting
            max-width: 1280px;
            // margin: 30px 30px;

            @include mobile {
                padding: 0px;
                
            }

            .homecontact-item-container-content-title {
                font-family: $roboto;
                font-size: 34px;
                font-weight: 400;
                line-height: 48px;
                letter-spacing: 0.0025em;
                color: $txt-main-color;
                text-align: center;
    
                margin-bottom: 24px
            }
    
            .homecontact-item-container-content-sales {
                display: flex;
                width: 100%;

                @include mobile {
                    flex-direction: column;
                }

                .homecontact-item-container-content-sales-left {
                    flex: 1;
                    height: 100%;
                    flex-direction: column;
                    display: flex;
                    // width: 500px;
                    // column-gap: 40px;
                    color: white;
                    font-family: $roboto;
                        // padding: 30px 20px;
                        row-gap: 20px;
                        font-size: 14px;
                        text-align: justify;
                        margin-right: 20px;
                    

                    @include mobile {
                        // display: flex;
                        // flex-direction: column;
                        // width: 100%;
                        // color: white;
                        font-family: $roboto;
                        // padding: 30px 20px;
                        // padding-top: 20px;
                        // row-gap: 20px;
                        font-size: 14px;
                        // text-align: justify;
                        padding-left: 20px;
                        // padding-right: 20px;
                        margin-bottom: 20px;
                        margin-top: 30px;
                        // margin-right: 100px;
                        // padding-bottom: 30px;
                        
                    }

                    .homecontact-item-container-content-sales-left-img {
                        width: 100%;    
                        // width: 100%;
                        // object-fit: content;
                              

                        @include mobile {
                            width: 100%;
                            // padding-bottom: 20px;
                        }
                    }

                }
                .homecontact-item-container-content-sales-right {
                    flex: 1;
                    width: 100%;
                                     

                    @include mobile{
                        width: 100%;
                        // height: fit-content;
                    }
                    .homecontact-item-container-content-sales-left-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        color: white;
                        font-size: 26px;
                        font-weight: 600;
                        font-family: $roboto;
                        @include mobile{
                            width: 100%;
                        }

                        .homecontact-item-container-content-sales-left-top {
                            flex: 1;
                            // height: 100%;
                            background-color: #6F4E37;

                            @include flex(flex-start, center);
                            padding: 35px 30px;
                            flex-direction: column;
                            row-gap: 20px;
                            margin-bottom: 0px;
                            font-family: $roboto;

                            .homecontact-item-container-content-sales-left-top-item {
                                display: flex;

                                .homecontact-item-container-content-sales-left-top-icon {
                                    color: white;
                                    margin-right: 10px;
                                    
                                }

                                .homecontact-item-container-content-sales-left-top-title {
                                    font-family: $roboto;
                                    font-size: 16px;
                                    font-weight: 400;
                                    // font-style: italic;
                                    text-align: left;
                                    // line-height: 48px;
                                    // letter-spacing: 0.0025em;
                                    color: white;
                                }
                            }
                        }

                        .homecontact-item-container-content-sales-left-bottom {
                            flex: 1;
                            height: 100%;
                            background-color: #5C4033;
                            padding: 40px 30px;
                            margin-bottom: 18px;
                            @include flex(flex-start, center);
                            @include mobile{
                                margin-bottom: 0px;
                            }
                            

                            .homecontact-item-container-content-sales-left-bottom-title {                               
                                
                                font-family: $roboto;
                                font-size: 16px;
                                font-weight: 400;
                                // font-style: italic;
                                text-align: left;
                                line-height: 30px;
                                // letter-spacing: 0.0025em;
                                color: $txt-white;
                               
                            }
                        }
                    }
                


                    .homecontact-item-container-content-sales-right-img {
                        display: flex;                        
                        width: 100%;
                        object-fit: content;
                        @include flex(center, center);

                        @include mobile {
                            // padding: 20px 20px;
                            // height: 240px;
                            width: 100%;
                            object-fit: content;
                            margin-top: 30px;
                            
                        }
                    }
                }
            }
        }
    }
}