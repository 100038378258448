@import '../../../sass/index.scss';

.homeproduct-item-container {
    @include flex(center, center);
    background-color: $main-bg;

    .homecontact-item-container-content {
        background-color: $main-bg;
        @include flex(center, center);
        width: 100%;

        .homecontact-item-container-content-main {
            // display: flex;
            // flex-direction: column;
            // padding-top: 20px;
            // padding-bottom: 50px;
            // padding-left: 250px;
            // padding-right: 250px;
            // width: 100%; //penting
            display: flex;
            flex-direction: column;
            padding: 34px 50px;
            width: 100%; //penting
            max-width: 1280px;

            @include mobile {
                padding: 34px 0px;
            }

            .homecontact-item-container-content-title {
                font-family: $roboto;
                font-size: 34px;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: 0.0025em;
                color: $txt-main-color;
                text-align: center;
                margin-bottom: 24px
            }
    
            .homecontact-item-container-content-product {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                column-gap: 100px;
                row-gap: 60px;

                place-items: center;
            }
        }
    }
}