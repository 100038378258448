@import '../../../sass/index.scss';

.footer-item-container {
    @include flex(center, center);
    flex-direction: column;
    width: 100%;
    

    .footer-item-content {
        background-color: #6F4E37;
        @include flex(center, center);
        width: 100%;
        

        .footer-item-content-main {
            display: flex;
            flex-direction: column;
            // padding: 40px 40px;
            padding-bottom: 40px;
            padding-top: 40px;
            width: 100%; //penting
            max-width: 1280px;
            
            @include mobile {
                flex-direction: column;
                width: 100%;
                padding-top: 40px;
                padding-left: 30px;
                padding-right: 30px;
            }


            .footer-item-content-main-item-container {
                display: flex;
                height: 100%;
                height: fit-content;
                column-gap: 64px;

                @include mobile {
                    flex-direction: column;
                    height: 100%;
                    row-gap: 20px;
                    
                }

                .footer-item-content-main-item {
                    // background-color: aqua;
                    flex: 1;
                    // display: flex;
                    // justify-content: center;
                    // margin-bottom: 20px;

                    .footer-item-content-main-item-title {   
                        display: flex;
                        justify-content: center;                     
                        font-family: $roboto;
                        font-size: 18px;
                        font-weight: 700;
                        color: $txt-white;

                        margin-bottom: 20px;
                    }

                    .footer-item-content-main-item-subtitle {
                        font-family: $roboto;
                        font-size: 14px;
                        font-weight: 400;
                        color: $txt-white;
                        text-align: justify;
                    }

                    .footer-item-content-main-item-link-container {
                        display: flex;
                        // flex-direction: row;
                        // justify-content: center;
                        // column-gap: 30px;
                        width: 100%;
                        

                        .footer-item-content-main-item-link {
                            font-family: $roboto;
                            font-size: 16px;
                            font-weight: 400;
                            color: $txt-white;
                            text-decoration: none;
                            width: 100%;
                            text-align: center;
                            
                        }
                    }

                    .footer-item-content-main-item-social-container {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        // display: grid;
                        // grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
                        column-gap: 30px;
                        // row-gap: 16px;

                        .footer-item-content-main-item-social {
                            height: 30px;

                            .footer-item-content-main-item-social-item {
                                color: $main-bg;
                                height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-item-copyright {
        background-color: #5C4033;
        width: 100%;
        height: 80px;
        @include flex(center, center);
        flex-direction: column;
        row-gap: 10px;

        @include mobile {
            // width: 100%;

            .footer-item-copyright-subtext {
                padding: 0 30px;
                text-align: center;
            }
        }

        .footer-item-copyright-text {
            font-family: $roboto;
            font-size: 14px;
            font-weight: 400;
            color: $txt-white;

            .footer-item-copyright-text-link {
                font-family: $roboto;
                font-size: 14px;
                font-weight: 600;
                color: $black;
                text-decoration: none;
            }
        }

        .footer-item-copyright-subtext {
            font-family: $roboto;
            font-size: 12px;
            font-weight: 400;
            color: $txt-white;
        }
    }

    // .footer-item-icon-wa {
    //     position: fixed;
    //     background-color: $wa-green;
    //     border-radius: 50%;
    //     width: 60px;
    //     height: 60px;
    //     @include flex(center, center);
    //     bottom: 20px;
    //     right: 20px;
    //     cursor: pointer;

    //     .footer-item-icon-wa-item {
    //         color: $main-bg;
    //     }
    // }
    // .footer-item-icon-wa:hover {
    //     background-color: $wa-green-hover;
    // }
}