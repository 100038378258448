$main-bg: #353535;
$main-bg-dark: #771e1e;
$black: #212121;
$red: rgb(170, 82, 0);
$red-light: #EC1A23;
$red-dark: #C9181F;
$grey: #8d8d8d;
$txt-white: #fafafa;
$wa-green: #25D366;
$wa-green-hover: #128C7E;
$grey-light: #e6e6e6;
$txt-main-color: #ffffff;

$main-color: #ffc802;
$main-color-light: #e2b84d;
$main-color-faq: #FDDD48;
$grey-thin: #e2e5e7;
$blue-dark: #152a4c;
$blue-light: #E2EDFF;
$blue-pale: #597BB2;
$blue-pale-light: #F6F9FF;

$green: #20b26c;
$grey-bg-light: #FAFAFA;
$grey-bg-dark: #F7F7F7;
$grey-bg-darker: #F6F6F6;
$grey-text-date: #666666;
$grey-text-tab-card: #999999;
$grey-text-modal-subtitle: #4B5563;
$grey-text-placeholder: #B8B8B8;
$grey-border: #E0E0E0;
$space-grey: #3D3D3D;
$grey-thin: #4B5563;
$grey-continue: #7A7A7A;
$grey-box: #8F8F8F;
$yellow-box: rgba(255, 200, 2, 0.3);
$yellow-pastel: #FFF2CC;

$bg-notes: $blue-light;


$txt-second-color: $grey;
$txt-white: #fafafa;
$txt-title-grey: $space-grey;
$txt-title-navy: $blue-dark;
$txt-title-field: $grey-thin;
$txt-title-continue: $grey-continue;

$navbar-link: $txt-white;
$navbar-link-hover: $blue-dark;
$navbar-link-hover-dark: $main-color;
$navbar-icon: $grey;

$dropdown-txt: $txt-main-color;
$dropdown-hover: $grey-light;

$txt-tab-active: $blue-dark;
$txt-tab-inactive: $grey;
$txt-tab-inactive-hover: $main-color-light;

$btn-main-bg: $main-color;
$btn-main-color: #fff;

$main-footer: #fff;

$asset-up: $green;
$asset-down: $red;

$roboto: 'Roboto', sans-serif;

////////////////////////////////////////////////

$header-height: 170px;
$header-tablet-height: 70px;
$header-mobile-height: 40px;
$header-shrink-height: 70px;

$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

$colors: (
  'blue': #4267b2,
  'orange': #fbb96b,
  'pink': #fe7e73,
  'white': #fff,
  'black': #000,
  'main': $main-color,
  'red': #ff0000,
);

$mobile-width: 600px;
$tablet-width: 1024px;

$numbers: (0, 1, 2, 3, 4, 5, 6, 7, 8);
