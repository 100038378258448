@import '../../sass/index.scss';

.product-page-container {
    @include flex(center, center);
    background-color: $main-bg;
    height: 100%;
    
    .product-page-content {
        background-color: $main-bg;
        @include flex(center, center);
        width: 100%;
        height: 100%;

        .product-page-content-main {
            display: flex;
            flex-direction: column;
            padding: 34px 50px;
            width: 100%; //penting
            max-width: 1280px;

            .product-page-content-main-title {
                font-family: $roboto;
                font-size: 34px;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: 0.0025em;
                color: $txt-main-color;
                padding-left: 25px;
                margin-bottom: 24px
            }

            .product-page-content-main-list {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                column-gap: 40px;
                row-gap: 40px;

                place-items: center;
            }
        }
    }
}