@import '../../../sass/index.scss';

.navbarlink-item-container {
    padding: 0 20px;
    height: 100%;
    @include flex(center, flex-start);
    position: relative;//parent dropdown absolute for right dropdown

    @include mobile {
        width: 100%;
        border-bottom: 1px solid grey;
    }
    
    .navbarlink-item-text {
        font-family: $roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.001em;
        color: $txt-white;

        text-decoration: none;
        cursor: pointer;
        @include flex(center, center);

        .navbarlink-item-icon {
            color: $txt-white;
            margin-left: 5px;
        }
    }
    .navbarlink-item-text:hover {
        font-weight: 600;
        color: $red;

        .navbarlink-item-icon {
            color: $red;
        }
    } 

    .navbarlink-item-order {
        border: 2px solid white;
        border-radius: 15px;
        padding: 5px 10px;

        .navbarlink-item-order-text {
            font-family: $roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.001em;
            color: $txt-white;

            cursor: pointer;
            text-decoration: none;
        }
    }

    .navbarlink-item-order:hover {
        border: 2px solid $red;

        .navbarlink-item-order-text {
            font-weight: 600;
            color: $red;
        }
    }
}