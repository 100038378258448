@import '../../../sass/index.scss';

.card-product-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 350px;
    min-height: 400px;
    background-color: $main-bg;
    border-radius: 8px;
    padding: 16px;
    color: white;
    transition: transform 0.3s ease;

    @include mobile {
        height: 100%;
        padding: 0px;
    }

    .card-product-item-top {

        .card-product-item-image {
            width: 100%;
            margin-bottom: 16px;
        }
    
        .card-product-item-title {
            font-family: $roboto;
            font-size: 18px;
            font-weight: 700;
            color: white;
    
            margin-bottom: 8px
        }
    
        .card-product-item-price {
            font-family: $roboto;
            font-size: 18px;
            font-weight: 700;
            color: white;
    
            margin-bottom: 8px
        }
    
        .card-product-item-subtitle {
            font-family: $roboto;
            font-size: 14px;
            font-weight: 400;
            color: white;
            text-align: justify;
    
            margin-bottom: 16px;
        }
    }

    .card-product-item-button-container {
        width: 100%;
        @include flex (center, center);

        .card-product-item-button {
            background-color: rgb(170, 82, 0);
            border-radius: 20px;
            height: 40px;
            width: 50%;
            @include flex (center, center);
            cursor: pointer;

            .card-product-item-button-text {
                font-family: $roboto;
                font-size: 18px;
                font-weight: 600;
                color: white;
            }
        }
    }
}

.card-product-item:hover{
    transform: scale(1.1);
}